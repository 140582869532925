<template>
  <component
    :is="asyncMenuDesktop"
    v-if="websiteConfig.menusState.desktop"
    :data="menuDesktopData"
    :visibleItems="visibleItems"
    expandMode="hover"
    @mounted="onMenuComponentMounted"
    @click:overlay="closeMenuDesktopHandler"
  >
    <template #loading>
      <Loader />
    </template>

    <template #rubricator="{ data, secondLevelVisibleHandler, activeMenu }">
      <template v-for="rubricator in data" :key="rubricator">
        <a
          v-if="rubricator.attributes.url"
          :href="rubricator.attributes.url"
          :rel="rubricator.attributes.outside ? 'nofollow' : undefined"
          :target="rubricator.attributes.outside ? '_blank' : undefined"
          :title="rubricator.attributes.title"
          :class="{
            ['p-menu-desktop__rubricator-item link-item link-item--rounded']: true,
            ['link-item--current']: rubricator === activeMenu,
          }"
          @mouseover="secondLevelVisibleHandler(rubricator)"
        >
          <span v-if="rubricator.icon.before" class="p-menu-desktop__rubricator-icon-before">
            <i :class="`icon-${rubricator.icon.before}`" />
          </span>

          <span class="p-menu-desktop__rubricator-text">
            {{ rubricator.name }}
          </span>

          <span v-if="rubricator.children.length" class="p-menu-desktop__rubricator-icon-after">
            <i class="icon-main-next" />
          </span>
        </a>

        <span
          v-else
          :title="rubricator.attributes.title"
          :class="{
            ['p-menu-desktop__rubricator-item link-item link-item--rounded']: true,
            ['link-item--current']: rubricator === activeMenu,
          }"
          @mouseover="secondLevelVisibleHandler(rubricator)"
        >
          <span v-if="rubricator.icon.before" class="p-menu-desktop__rubricator-icon-before">
            <i :class="`icon-${rubricator.icon.before}`" />
          </span>

          <span class="p-menu-desktop__rubricator-text">
            {{ rubricator.name }}
          </span>

          <span v-if="rubricator.children.length" class="p-menu-desktop__rubricator-icon-after">
            <i class="icon-main-next" />
          </span>
        </span>
      </template>
    </template>

    <template #menu="{ data, mapShowMoreState, showMoreHandler, isMenuElementHidden }">
      <div :class="`p-menu-desktop__menu`">
        <h3 :class="`p-menu-desktop__menu-title`">
          <a v-if="data.attributes.url" class="no-decoration" :href="data.attributes.url">
            {{ data.name }}
          </a>
          <span v-else>{{ data.name }}</span>
        </h3>

        <div class="p-menu-desktop__menu-wrapper">
          <div v-for="col in makeCols(data.children, 3)" class="p-menu-desktop__second-level-col">
            <div v-for="secondLevel in col" class="p-menu-desktop__second-level-items">
              <h5>
                <a
                  v-if="secondLevel.attributes.url"
                  :href="secondLevel.attributes.url"
                  :rel="secondLevel.attributes.outside ? 'nofollow' : undefined"
                  :target="secondLevel.attributes.outside ? '_blank' : undefined"
                  :title="secondLevel.attributes.title"
                  class="p-menu-desktop__menu-subtitle link-item link-item--rounded"
                >
                  {{ secondLevel.name }}
                </a>
                <span class="p-menu-desktop__menu-subtitle link-item link-item--rounded" v-else>
                  {{ secondLevel.name }}
                </span>
              </h5>

              <div v-if="secondLevel.children" class="p-menu-desktop__submenu">
                <span
                  v-for="(thirdLevel, thirdLevelIdx) in secondLevel.children"
                  :key="thirdLevelIdx"
                  :class="{
                    ['p-menu-desktop__submenu-item']: true,
                    ['p-menu-desktop__submenu-item--hidden']: isMenuElementHidden(
                      thirdLevelIdx,
                      secondLevel.children,
                    ),
                  }"
                >
                  <a
                    v-if="thirdLevel.attributes.url"
                    :href="thirdLevel.attributes.url"
                    :rel="thirdLevel.attributes.outside ? 'nofollow' : undefined"
                    :target="thirdLevel.attributes.outside ? '_blank' : undefined"
                    :title="thirdLevel.attributes.title"
                    class="link-item link-item--rounded"
                  >
                    {{ thirdLevel.name }}
                  </a>
                </span>

                <div
                  v-if="visibleItems && secondLevel.children.length > visibleItems"
                  class="p-menu-desktop__submenu-more"
                >
                  <Button
                    css-class="button-icon-text"
                    icon-pos="right"
                    modifier="primary"
                    @click="showMoreHandler(secondLevel.children)"
                  >
                    {{ mapShowMoreState.get(secondLevel.children) ? 'Hide' : 'More' }}
                    <template #icon>
                      <i
                        :class="[
                          mapShowMoreState.get(secondLevel.children)
                            ? 'icon-main-up'
                            : 'icon-main-down',
                        ]"
                      ></i>
                    </template>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </component>
</template>

<script lang="ts" setup>
// Vue
import { ref } from 'vue';

// Components
import Button from '@ice-products-ui/icecorp-vue-library/Button';
import Loader from '@ice-products-ui/icecorp-vue-library/Loader';

// Types
import type { IMenuDesktop } from '@ice-products-ui/icecorp-vue-library/MenuDesktop';
import type { TWebsiteConfig } from '~/typings/types/website-config.types';

// Composables
import useApiBase from '~/composables/api/base/useApiBase';
import useMenu from '~/composables/organisms/useMenu';
import { useIceCoreGenericRequest } from '#imports';

// Utils
import makeCols from '~/utils/arrays/makeCols';

defineOptions({
  name: 'OMenuDesktop',
});

const websiteConfig = useState<TWebsiteConfig>('config');

const asyncMenuDesktop = defineAsyncComponent(
  async () => await import('@ice-products-ui/icecorp-vue-library/MenuDesktop'),
);

const { baseApiUrl } = useApiBase();
const { closeMenuDesktopHandler } = useMenu();
const { getGenericContextsData } = useIceCoreGenericRequest();

const menuDesktopData = ref<IMenuDesktop[]>([]);
const visibleItems = ref<number>(6);

const onMenuComponentMounted = async () => {
  menuDesktopData.value = [];

  menuDesktopData.value = await $fetch(`menu/services?contexts=${getGenericContextsData.value}`, {
    baseURL: baseApiUrl,
    method: 'GET',
  });
};
</script>
